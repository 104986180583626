import { APP_ID, APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { MsTeamsAuthInterceptor } from '@app/ms-teams/services/ms-teams-auth/ms-teams-auth.interceptor';
import { microsoftTeamsProvider, MicrosoftTeamsToken } from '@app/ms-teams/msteams.token';
import { MSTeamsMsal2Token, teamsMsal2TokenProvider } from '@app/ms-teams/msteams-msal2.token';
import { MSTeamsMgtToken, teamsMgtTokenProvider } from '@app/ms-teams/msteams-mgt.token';
import { CookieService } from 'ngx-cookie-service';
import { LocalizationService } from '@app/shared/localization.service';
import { initAppState } from '@app/app-initializer';
import { AuthService, LDFlagsService, WebEnvironmentService } from '@dg/shared-services';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '@app/shared/services/configuration/configuration.service';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { SharedModule } from '@app/shared/shared.module';
import { BulkIconRegistryComponent } from '@app/shared/components/icon/bulk-icon-registry.component';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule } from 'ngx-toastr';
import { ToastComponent } from '@app/shared/components/toast/toast.component';
import { createTranslateLoader } from '@app/shared/dg-translate-loader';
import { MsTeamsBootstrapComponent } from '@app/ms-teams/components/ms-teams-bootstrap/ms-teams-bootstrap.component';
import { RouterModule } from '@angular/router';
import { SharedProvidersModule } from '@app/shared/shared-providers.module';
import { TagsSharedModule } from '@app/tags/tags-shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { UserContentModule } from '@app/user-content/user-content.module';
import { AnalyticsGuard } from '@app/analytics/guards/analytics.guard';

@NgModule({
  declarations: [MsTeamsBootstrapComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    UserContentModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      tapToDismiss: false,
      maxOpened: 1, // prevents stacking of toasts messages
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [NgxHttpClient, AuthService],
      },
    }),
    SharedModule,
    SharedProvidersModule,
    TagsSharedModule,
    RouterModule.forRoot([
      {
        path: 'degreed-ms-teams/app',
        loadChildren: () => import('./ms-teams.module').then(m => m.MsTeamsModule),
      }
    ]),

    // standalone components
    BulkIconRegistryComponent,
  ],
  providers: [
    TitleCasePipe,
    NgxHttpClient,
    CookieService,
    AnalyticsGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsTeamsAuthInterceptor,
      multi: true,
    },
    { provide: MicrosoftTeamsToken, useFactory: microsoftTeamsProvider },
    { provide: MSTeamsMsal2Token, useFactory: teamsMsal2TokenProvider },
    { provide: MSTeamsMgtToken, useFactory: teamsMgtTokenProvider },
    {
      provide: LOCALE_ID,
      deps: [LocalizationService],
      useFactory: (localizationService: LocalizationService) => {
        const { userLocale } = localizationService;
        // lazy-load the locales which we need for localizing dates in some areas of the app
        localizationService.loadLocale(userLocale);
        return userLocale;
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAppState,
      deps: [
        WebEnvironmentService,
        AuthService,
        TranslateService,
        ConfigurationService,
        LocalizationService,
        LDFlagsService,
      ],
      multi: true,
    },
    {
      provide: APP_ID,
      useValue: 'ms_teams_app_id'
    }
  ],
  bootstrap: [MsTeamsBootstrapComponent],
})
export class AppMsTeamsBootstrapModule {}
