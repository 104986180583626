import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TagsModule } from '@app/tags/tags.module';
import { AccomplishmentModalComponent } from './user-outcome/accomplishment-modal/accomplishment-modal.component';

import { GlobalAddResultsComponent } from './global-add-results/global-add-results.component';
import { PostFormComponent } from './user-input/post-form/post-form.component';
import { TaskModalComponent } from './user-input/task-modal/task-modal.component';

import { MarkdownModule } from '@app/markdown/markdown.module';
import { UserOutcomeImageUploadAdapter } from './services/adapters/user-outcome-image-upload.adapter';
import { BadgeUploadAdapter } from './user-outcome/badge-modal/badge-upload.adapter';
import { UploaderModule } from '@app/uploader/uploader.module';
import { BadgeModalComponent } from './user-outcome/badge-modal/badge-modal.component';
import { AwardModalComponent } from './user-outcome/award-modal/award-modal.component';
import { CertificateModalComponent } from './user-outcome/certificate-modal/certificate-modal.component';
import { DegreeModalComponent } from './user-outcome/degree-modal/degree-modal.component';
import { CourseFormalFormComponent } from './user-input/course-form/course-formal-form/course-formal-form.component';
import { CourseInformalFormComponent } from './user-input/course-form/course-informal-form/course-informal-form.component';
import { CourseModalComponent } from './user-input/course-form/course-modal.component';
import { CourseFormDomainService } from './user-input/course-form/course-form-domain.service';
import { GroupsSharedModule } from '@app/groups/groups-shared.module';
import { FormFieldsModule } from '@app/form-fields/form-fields.module';
import { OrgsSharedModule } from '@app/orgs/orgs-shared.module';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { UserInputModule } from './user-input/user-input.module';
import { CertificateUploadAdapter } from './user-outcome/certificate-modal/certificate-upload.adapter';
import { ImageUrlService } from './user-input/services/image-url.service';
import { ExperienceModalComponent } from './user-input/experience-modal/experience-modal.component';
import { AuthorSharedModule } from '@app/author/author-shared.module';
import { ArticleCatalogComponent } from './user-input-v2/inputs/article/components/catalog/article-catalog.component';
import { ArticlePathwaysPlansComponent } from './user-input-v2/inputs/article/components/pathways-and-plans/article-pathways-plans.component';
import { ArticleGlobalAddComponent } from '@app/user-content/user-input-v2/inputs/article/components/global-add/article-global-add.component';
import { AdvancedSettingsComponent } from './user-input-v2/inputs/shared/components/advanced-settings-component/advanced-settings.component';
import { DurationComponent } from './user-input-v2/inputs/shared/components/duration/duration.component';
import { UserModule } from '@app/user/user.module';
import { AddToCatalogComponent } from './user-input-v2/inputs/shared/components/add-to-catalog/add-to-catalog.component';
import { InputsSharedModule } from '@app/inputs/inputs-shared.module';
import { VideoCatalogComponent } from './user-input-v2/inputs/video/components/catalog/video-catalog.component';
import { VideoPathwaysPlansComponent } from './user-input-v2/inputs/video/components/pathways-and-plans/video-pathways-plans.component';
import { VideoGlobalAddComponent } from './user-input-v2/inputs/video/components/global-add/video-global-add.component';
import { DfLabelMarkerModule } from '@lib/fresco';
import { ExperienceGlobalAddComponent } from './user-input-v2/inputs/experience/components/global-add/experience-global-add.component';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';
import { CourseCatalogComponent } from './user-input-v2/inputs/course/components/catalog/course-catalog.component';
import { ExperienceGlobalAddInputFacade } from './user-input-v2/inputs/experience/services/global-add/experience-global-add.facade';
import {
  ExperienceMapperService,
  ExperienceTrackerService,
  ExperienceNotificationService,
  ExperienceService,
} from './user-input-v2/inputs/experience/services';
import { SessionDetailsComponent } from './user-input-v2/inputs/shared/components/session-details/session-details.component';
import { AccomplishmentGlobalAddComponent } from './user-outcome-v2/outcomes/accomplishment/components/global-add/accomplishment-global-add.component';
import {
  AccomplishmentMapperService,
  AccomplishmentService,
  AccomplishmentTrackerService,
} from './user-outcome-v2/outcomes/accomplishment/services';
import { AccomplishmentGlobalAddFacade } from './user-outcome-v2/outcomes/accomplishment/services/global-add/accomplishment-global-add.facade';
import { OutcomesService } from './user-outcome-v2/services/outcomes.service';

import { CertificateComponent } from './user-outcome-v2/outcomes/certificate/components/global-add/certificate-modal.component';
import { CertificateGlobalAddFacade } from './user-outcome-v2/outcomes/certificate/services/global-add/certificate-global-add.facade';
import { CertificateMapperService } from './user-outcome-v2/outcomes/certificate/services/certificate-mapper.service';
import { CertificateTrackerService } from './user-outcome-v2/outcomes/certificate/services/certificate-tracker.service';
import { CertificateService } from './user-outcome-v2/outcomes/certificate/services/certificate.service';

import { BadgeGlobalAddComponent } from './user-outcome-v2/outcomes/badge/components/global-add/badge-global-add.component';
import {
  BadgeMapperService,
  BadgeService,
  BadgeTrackerService,
} from './user-outcome-v2/outcomes/badge/services';
import { BadgeGlobalAddFacade } from './user-outcome-v2/outcomes/badge/services/global-add/badge-global-add.facade';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { ReactiveFormsModule } from '@angular/forms';
import { AwardComponent } from './user-outcome-v2/outcomes/award/components/global-add/award-modal.component';
import { AwardGlobalAddFacade } from './user-outcome-v2/outcomes/award/services/global-add/award-global-add.facade';
import { AwardService } from './user-outcome-v2/outcomes/award/services/award.service';
import { AwardMapperService } from './user-outcome-v2/outcomes/award/services/award-mapper.service';
import { DegreeGlobalAddComponent } from './user-outcome-v2/outcomes/degree/components/global-add/degree-global-add.component';
import { DegreeGlobalAddFacade } from './user-outcome-v2/outcomes/degree/services/global-add/degree-global-add.facade';
import {
  DegreeMapperService,
  DegreeService,
  DegreeTrackerService,
} from './user-outcome-v2/outcomes/degree/services';
import { CourseGlobalAddFacade } from './user-input-v2/inputs/course/services/global-add/course-global-add.facade.service';
import { CourseGlobalAddComponent } from './user-input-v2/inputs/course/components/global-add/course-global-add.component';
import { CoursePathwaysPlansComponent } from './user-input-v2/inputs/course/components/pathways-and-plans/course-pathways-plans.component';
import { VideoGlobalAddInputFacade } from './user-input-v2/inputs/video/services/global-add/video-global-add.facade';
import { ArticleGlobalAddInputFacade } from './user-input-v2/inputs/article/services/global-add/article-global-add.facade';

/** A module for user content related items such as User Inputs and Achievements (a.k.a Outcomes) */
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TagsModule,
    MarkdownModule,
    UploaderModule,
    GroupsSharedModule,
    FormFieldsModule,
    OrgsSharedModule,
    InputsSharedModule,
    UserInputModule,
    AuthorSharedModule,
    UserModule,
    DfLabelMarkerModule,
    //standalone components
    AutofocusDirective,
  ],
  declarations: [
    ExperienceModalComponent,
    AwardModalComponent,
    BadgeModalComponent,
    CertificateModalComponent,
    AccomplishmentModalComponent,
    DegreeModalComponent,
    GlobalAddResultsComponent,
    PostFormComponent,
    TaskModalComponent,
    CourseModalComponent,
    CourseFormalFormComponent,
    CourseInformalFormComponent,
    ModalContainerComponent,
    ArticleCatalogComponent,
    ArticlePathwaysPlansComponent,
    ArticleGlobalAddComponent,
    AdvancedSettingsComponent,
    DurationComponent,
    AddToCatalogComponent,
    VideoCatalogComponent,
    VideoPathwaysPlansComponent,
    VideoGlobalAddComponent,
    CourseCatalogComponent,
    CourseGlobalAddComponent,
    CoursePathwaysPlansComponent,
    ExperienceGlobalAddComponent,
    SessionDetailsComponent,
    AccomplishmentGlobalAddComponent,
    CertificateComponent,
    BadgeGlobalAddComponent,
    AwardComponent,
    DegreeGlobalAddComponent,
  ],
  exports: [
    ExperienceModalComponent,
    AwardModalComponent,
    BadgeModalComponent,
    CertificateModalComponent,
    DegreeModalComponent,
    GlobalAddResultsComponent,
    CourseModalComponent,
    CourseFormalFormComponent,
    CourseInformalFormComponent,
    ModalContainerComponent,
    ArticleCatalogComponent,
    AdvancedSettingsComponent,
    DurationComponent,
    SessionDetailsComponent,
  ],
  providers: [
    UserOutcomeImageUploadAdapter,
    BadgeUploadAdapter,
    CertificateUploadAdapter,
    CourseFormDomainService,
    ImageUrlService,
    AccomplishmentGlobalAddFacade,
    AccomplishmentMapperService,
    AccomplishmentService,
    AccomplishmentTrackerService,
    OutcomesService,
    ExperienceGlobalAddInputFacade,
    ExperienceMapperService,
    ExperienceNotificationService,
    ExperienceTrackerService,
    ExperienceService,
    OutcomesService,
    CertificateGlobalAddFacade,
    CertificateMapperService,
    CertificateTrackerService,
    CertificateService,
    OutcomesService,
    ExperienceGlobalAddInputFacade,
    ExperienceMapperService,
    ExperienceNotificationService,
    ExperienceTrackerService,
    ExperienceService,
    BadgeGlobalAddFacade,
    BadgeService,
    BadgeMapperService,
    BadgeTrackerService,
    DegreeGlobalAddFacade,
    DegreeService,
    DegreeMapperService,
    DegreeTrackerService,
    OutcomeNotificationService,
    AwardGlobalAddFacade,
    AwardService,
    AwardMapperService,
    VideoGlobalAddInputFacade,
    ArticleGlobalAddInputFacade,
    CourseGlobalAddFacade,
  ],
})
export class UserContentModule {}
