import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthUser } from '@app/account/account-api.model';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { MentionService } from '@app/comments/services/mention.service';
import { FeedbackService } from '@app/feedback/services/feedback.service';
import { GroupPrivacyLevel } from '@app/groups/group-api';
import {
  CommentFeedItemModel,
  CommentModel,
  ReplyCommentModel,
} from '@app/inputs/inputs-api.model';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { AuthService } from '@app/shared/services/auth.service';
import { isReplyCommentModel } from '@app/shared/utils/type-guard-helpers';
import { TranslateService } from '@ngx-translate/core';
import { finalize, switchMap } from 'rxjs/operators';
import {
  CommentFeedModel,
  CommentSubmission,
  CommentThreadContext,
  Resource,
} from '../../comments.model';
import { CommentFieldComponent } from '../comment-field/comment-field.component';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { FocusStackService } from '@app/shared/services/focus-stack.service';

@Component({
  selector: 'dgx-comment-thread',
  styleUrls: ['./comment-thread.component.scss'],
  templateUrl: './comment-thread.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentThreadComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  /** The associated resource */
  @Input() public resource: Resource;
  /** The associated group information  */
  @Input() public referenceUserKey?: number;
  /** i.e. ownerId */
  @Input() public parentReferenceId?: number;
  @Input() public groupId?: number;
  @Input() public groupPrivacy?: GroupPrivacyLevel;
  @Input() public context?: CommentThreadContext;
  @Input() public userHasCommented?: boolean;
  @Input() public showAllComments?: boolean = false;
  /** When true, the users profile picture will show next to the comment field */
  @Input() public showUserProfilePic: boolean = false;
  /** Comments are expanded */
  @Input() public isExpanded = false;

  @Output() public commentAdded = new EventEmitter<void>();
  @Output() public commentDeleted = new EventEmitter<void>();

  /** The component used to enter a new comment */
  @ViewChild(CommentFieldComponent)
  public commentField: CommentFieldComponent;

  /** Paginated comment feed model */
  public comments: CommentFeedModel;
  /** Comments are loading */
  public isLoading = true;
  /** The next page of comments is loading */
  public isLoadingMore = false;
  /** A new comment is being submitted to the server */
  public isSubmitting = false;
  /** Show or hide the comment thread based on the mobile query param */
  public showCommentThread = true;
  public authUser: AuthUser;
  public i18n = this.translateService.instant([
    'Core_Delete',
    'Core_FlagAsInappropriate',
    'Core_Loading',
    'Core_LoadMore',
    'dgComment_CommentHidden',
    'dgComment_CollapseOneComment',
    'dgComment_ShowOneComment',
    'dgComment_ReplyHidden',
    'dgComment_ShowPreviousReplies',
  ]);

  private readonly replyDisplayCount = 3;
  constructor(
    private cdr: ChangeDetectorRef,
    private commentApiService: CommentsApiService,
    private feedbackService: FeedbackService,
    private translateService: TranslateService,
    private authService: AuthService,
    private mentionService: MentionService,
    private WindowLayoutService: WindowLayoutService,
    private focusStackService: FocusStackService,
    private route: ActivatedRoute
  ) {
    super();
  }

  public ngOnInit(): void {
    // Subscribe to queryParams to handle mobile=1
    this.route.queryParams
    .pipe(this.takeUntilDestroyed())
    .subscribe((params) => {
      const mobile = params['mobile'];
      // Hide the thread if mobile=1
      this.showCommentThread = mobile !== '1';
    });

    this.authUser = this.authService.authUser;
    this.getComments();
  }

  public getComments() {
    const commentTake = this.showAllComments ? 1000 : undefined; // requesting a max of 1000 comments

    this.commentApiService
      .getComments(this.resource, commentTake)
      .pipe(
        this.takeUntilDestroyed(),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe({
        next: (result) => {
          this.comments = result;
          this.userHasCommented ??= this.comments?.feed.some(
            (comment) => comment.isOwner
          );
          this.cdr.detectChanges();
        },
        error: () => {
          // set comments to a  blank CommentFeedModel on error.
          this.comments = {
            objectType: null,
            objectId: null,
            remainingComments: null,
            feed: [],
            hasMoreItems: null,
          };
        },
      });
  }

  public showCommentField() {
    // first check if the user has permission
    if (!this.authUser?.canComment) {
      return false;
    }

    // Extension never shows the add comment field.
    if (this.context === 'Extension' || this.WindowLayoutService.isIframe) {
      return false;
    }

    return !this.userHasCommented;
  }

  public createComment(newComment: CommentSubmission) {
    this.isSubmitting = true;
    const _comment = this.mentionService.getCommentWithMentionKeys(
      newComment.value
    );

    this.commentApiService
      .addComment(
        this.resource,
        _comment,
        null,
        this.parentReferenceId,
        this.referenceUserKey,
        newComment.mentions
      )
      .subscribe({
        next: (comment) => {
          comment.mentions = newComment.mentions ?? undefined;
          this.comments.feed.unshift(comment);
          if (this.commentField) {
            this.commentField.value = '';
          }
          this.userHasCommented = this.comments?.feed.some(
            (comment) => comment.isOwner
          );
        },
        complete: () => {
          this.isSubmitting = false;
          this.commentAdded.emit();
          this.cdr.markForCheck();
          this.focusStackService.pop();
        },
      });
    //
  }

  public getMenuConfig(
    comment: CommentFeedItemModel | ReplyCommentModel
  ): MenuViewModel[] {
    return [
      {
        id: 'delete',
        title: this.i18n.Core_Delete,
        isHidden: () => !comment.isOwner,
        defaultAction: (event) => {
          this.commentApiService
            .deleteComment(comment, event)
            .subscribe((data) => {
              if (data !== 'dismissModal') {
                if (isReplyCommentModel(comment)) {
                  const parentIdx = this.comments.feed.findIndex(
                    (parentComment) => parentComment.id === comment.parentId
                  );
                  if (parentIdx !== -1) {
                    const replyIdx =
                      this.comments.feed[parentIdx].replies.indexOf(comment);
                    if (replyIdx !== -1) {
                      this.comments.feed[parentIdx].replies.splice(replyIdx, 1);
                    }
                  }
                } else {
                  const idx = this.comments.feed.indexOf(comment);
                  if (idx !== -1) {
                    this.comments.feed.splice(idx, 1);
                  }
                  this.userHasCommented = false;
                }
                this.commentDeleted.emit();
                this.cdr.markForCheck();
              }
            });
        },
      },
      {
        id: 'flag',
        title: this.i18n.Core_FlagAsInappropriate,
        defaultAction: (event) => {
          this.feedbackService
            .showReportProblemModal(
              {
                itemType: this.feedbackService.feedbackItemType.Comment,
                feedbackType: this.feedbackService.feedbackType.Inappropriate,
                itemId: comment.id,
                contextType: this.feedbackService.feedbackContextType.Unknown,
                contextId: this.comments.objectId,
              },
              event.target as HTMLElement
            )
            .pipe(
              switchMap(() => {
                return this.commentApiService.flagComment(comment).pipe(
                  finalize(() => {
                    this.cdr.detectChanges();
                  })
                );
              })
            )
            .subscribe();
        },
      },
    ];
  }

  public loadMore() {
    this.isLoadingMore = true;
    this.commentApiService.loadMore(this.comments).subscribe({
      complete: () => {
        this.isLoadingMore = false;
        this.cdr.markForCheck();
      },
    });
  }

  public loadMoreReplies(comment: CommentFeedItemModel) {
    // isLoadingReplies is only used in the component UI
    comment.isLoadingReplies = true;
    this.cdr.detectChanges();
    this.commentApiService
      .getReplies(
        this.resource.resourceType,
        this.resource.resourceId,
        comment.id,
        comment.remainingReplies,
        this.replyDisplayCount
      )
      .pipe(
        finalize(() => {
          comment.isLoadingReplies = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe((data) => {
        if (data) {
          const reverseReplies = data.replies.reverse();
          comment.replies = reverseReplies.concat(comment.replies);
          comment.remainingReplies = data.remainingReplies;
        }
      });
  }

  public toggleExpanded() {
    this.isExpanded = !this.isExpanded;
    this.focusStackService.push();
  }
  public getInappropriateRepliesButtonText(comment): string {
    if (comment.replies.length === 1) {
      return comment.isExpanded
        ? this.i18n.dgComment_CollapseOneComment
        : this.i18n.dgComment_ShowOneComment;
    } else if (comment.replies.length > 1) {
      const count = comment.replies.length;
      return comment.isExpanded
        ? this.translateService.instant('dgComment_CollapseCommentsFormat', {
            count,
          })
        : this.translateService.instant('dgComment_ShowCommentsFormat', {
            count,
          });
    }
  }

  public commentId(index, comment: CommentFeedItemModel) {
    return comment.id;
  }

  public getShowCommentsLabel() {
    return this.translateService.instant('dgComment_ShowComments', {
      count: this.comments?.remainingComments + this.comments?.feed.length,
    });
  }

  public getPreviousRepliesLabel(comment: CommentFeedItemModel) {
    return this.translateService.instant(
      'dgComment_ShowMultiplePreviousRepliesFormat',
      { count: comment.remainingReplies }
    );
  }

  // linter fixes, should be able to refactor this out
  public asCommentModel(
    comment: CommentFeedItemModel | ReplyCommentModel
  ): CommentModel {
    return comment as CommentModel;
  }
}
