import { APP_ID, APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// third-party
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { SwiperModule } from 'swiper/angular';

// misc
import { initAppState } from '@app/app-initializer';
import { createTranslateLoader } from '@app/shared/dg-translate-loader';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
// modules

import { AppRoutingModule } from '@app/routing/app-routing.module';
import { SharedProvidersModule } from '@app/shared/shared-providers.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslationModule } from '@app/translation/translation.module';
import { UploaderModule } from '@app/uploader/uploader.module';
import { ContentHostingDataAccessModule } from '@degreed/content-hosting';

import { DataPrivacyModule } from '@app/data-privacy/data-privacy.module';

// services
import { AuthService } from '@app/shared/services/auth.service';
import { ConfigurationService } from '@app/shared/services/configuration/configuration.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { LocalizationService } from '@app/shared/localization.service';

import { ApolloLayoutProvider, APOLLO_LAYOUT_TOKEN } from '@app/layout';

// components
import { AppComponent } from '@app/app.component';
import { BulkIconRegistryComponent } from '@app/shared/components/icon/bulk-icon-registry.component';
import { AnalyticsGuard } from './analytics/guards/analytics.guard';
import { AuthorSharedModule } from './author/author-shared.module';
import { FormFieldsModule } from './form-fields/form-fields.module';
import { ToastComponent } from './shared/components/toast/toast.component';
import { LDFlagsService } from './shared/services/ld-flags.service';

import { ApolloAngularModule } from '@degreed/apollo-angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    /* Angular modules */
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      tapToDismiss: false,
      maxOpened: 1, // prevents stacking of toasts messages
    }),
    HttpClientModule,
    HttpClientJsonpModule,
    /* Third-party modules */
    NgbModalModule,
    NgxSliderModule,
    SwiperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [NgxHttpClient, AuthService],
      },
    }),
    /* App modules */
    // AccountModule,
    // ChannelDowngradesModule,
    // ContentCatalogModule,
    // ChartsSharedModule,
    // CredSparkModule,
    // FlexEdModule,
    // FlexFrameworkSharedModule,
    // FeedbackModule, // downgraded component - Imports: SharedModule
    // InsightsModule,
    // GroupsModule,
    // InputsModule, // this was provided through the LayoutModule, now is needed for downgraded <dgx-input-page>
    // LayoutModule, // downgraded component - Imports: Shared, Markdown, SearchShared, NotificationShared, GlobalAdd
    // LearnerHomeModule,
    // MentoringModule,
    // OpportunitiesModalsModule,
    // OpportunitiesSharedModule,
    // GroupsSharedModule,
    // OrgsSharedModule,
    // PathwayPageModule,
    // BrowserExtensionModule,
    SharedModule,
    SharedProvidersModule,
    // TagsModule,
    // TargetModule,
    // TeamModule,
    TranslationModule,
    UploaderModule,
    // UserModule,
    // UserContentModule,
    AppRoutingModule,
    ContentHostingDataAccessModule,
    // EvaluationModule,
    // CredentialModule,
    // ProfileSettingsModule,
    // ContentHostingModule, // downgraded component - Imports: SharedModule, CommentsModule, SharedProvidersModule, ContentHostingDataAccessModule
    // SessionModule, // downgraded component - Imports: N/A

    // required by app.component.ts - Imports: SharedModule (TBD refactor)
    DataPrivacyModule,
    // TODO: the tracking.service still depends on the router,
    FormFieldsModule, // required for fields building formly 'providedIn:root' - Can't be removed till we refactor out the dependency on SharedOrgModule
    AuthorSharedModule, // need providers for recording a video - figure out how to get this to work while not in root

    // standalone components
    BulkIconRegistryComponent,
    ApolloAngularModule.forRoot(),
  ],
  providers: [
    TitleCasePipe,
    CookieService,
    AnalyticsGuard,
    {
      provide: LOCALE_ID,
      deps: [LocalizationService],
      useFactory: (localizationService: LocalizationService) => {
        const { userLocale } = localizationService;
        // lazy-load the locales which we need for localizing dates in some areas of the app
        localizationService.loadLocale(userLocale);
        return userLocale;
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAppState,
      deps: [
        WebEnvironmentService,
        AuthService,
        TranslateService,
        ConfigurationService,
        LocalizationService,
        LDFlagsService,
      ],
      multi: true,
    },
    {
      provide: APP_ID,
      useValue: 'dgx_web_app_id',
    },
    ApolloLayoutProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
