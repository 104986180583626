<div
  class="input-detail-modal"
  [class.input-detail-modal--clickable]="isClickable"
  *ngIf="!isLoading; else skeleton"
>
  <div [class.input-card]="!isDetailModal">
    <!-- Hero Image -->
    <div [ngSwitch]="previewType" class="input-detail-modal__{{ previewType }}">
      <ng-container *ngIf="!isPost">
        <dgx-image-layout
          *ngSwitchCase="'image'"
          [resourceId]="resource.resourceId"
          [resourceType]="resource.resourceType"
          format="details-modal"
          [imageSrc]="resource.displayImageUrl || resource.imageUrl"
          [videoUrl]="resource.videoInfo?.videoUrl"
          [providerImage]="resource.providerSummary?.image"
          (click)="onImageClick($event)"
        ></dgx-image-layout>
      </ng-container>
      <dgx-video-player
        *ngSwitchCase="'video'"
        [resourceId]="resource.resourceId"
        [resourceUrl]="resource.videoInfo.videoUrl"
        [resourceTitle]="resource.title"
        [videoType]="resource.videoInfo.videoType"
      ></dgx-video-player>
    </div>

    <!-- Metadata -->
    <div
      class="l_flexbar guts-p-t-1-half guts-m-b-1-half"
      [ngClass]="{
        'guts-m-l-2': !isDetailModal,
      }"
    >
      <img
        *ngIf="resource.isEndorsed && endorsedSrc"
        class="input-detail-modal__endorsed-icon guts-m-r-1 guts-m-b-half"
        [src]="endorsedSrc + '?brandlogo'"
        [alt]="i18n.Core_Endorsed"
        [ngbTooltip]="i18n.Core_EndorsedTooltip"
      />
      <div>
        <ng-container *ngTemplateOutlet="metadata"></ng-container>
      </div>
    </div>

    <div [class.guts-p-h-2]="!isDetailModal">
      <!-- Title -->
      <h2
        class="input-detail-modal__title-container"
        id="input-details-header-label"
      >
        <a
          *ngIf="isClickable"
          #link
          [href]="url"
          [attr.aria-describedby]="'a11yNewWindowDescription'"
          [attr.target]="'_blank'"
          [attr.rel]="'noopener noreferrer'"
          class="clamp input-detail-modal__title input-detail-modal__title-clickable"
          [attr.title]="resource.title | decodeHtml"
          [attr.aria-label]="resource.title | decodeHtml"
          data-dgat="input-detail-modal-9e1"
          (click)="onContentClicked()"
          >{{ resource.title | decodeHtml }}
        </a>
        <span *ngIf="!isClickable" class="input-detail-modal__title clamp">
          {{ resource.title | decodeHtml }}
        </span>
      </h2>

      <!-- Summary -->
      <div class="guts-m-t-1-half">
        <ng-container>
          <ng-container>
            <div class="l_flex l_flex-row">
              <div class="l_flex-grow" [class.guts-p-r-1]="hasTags">
                <div class="guts-p-b-1">
                  <ng-container *ngTemplateOutlet="summarytext"></ng-container>
                </div>
              </div>
              <div
                *ngIf="hasTags"
                class="input-detail-modal__skills guts-p-l-1"
              >
                <p class="h4 guts-m-b-1">{{ i18n.Core_Skills }}</p>
                <div class="l_flex l_flex-column guts-p-b-half">
                  <!-- User assigned skills -->
                  <dgx-action-button
                    *ngFor="let userTag of userTags"
                    size="xs"
                    design="square"
                    class="guts-m-b-half"
                    (click)="searchTag(userTag)"
                    dgatInput="input-card-ad9"
                    [tooltip]="userTag"
                  >
                    {{ userTag }}
                  </dgx-action-button>
                  <!-- Org assigned skills -->
                  <dgx-action-button
                    *ngFor="let orgTag of uniqueOrgTags"
                    size="xs"
                    design="square"
                    class="guts-m-b-half"
                    [secondary]="true"
                    (click)="searchTag(orgTag)"
                    dgatInput="input-card-ad9"
                    [tooltip]="orgTag"
                  >
                    {{ orgTag }}
                  </dgx-action-button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <!-- Live Sessions -->
      <div
        class="input-detail-modal__live-session"
        *ngIf="liveSessions?.length > 0"
      >
        <h4 class="h4">{{ sessionLabel }}</h4>
        <div
          *ngFor="let session of liveSessions"
          data-dgat="live-event-details-761"
          class="box guts-m-v-1-quart guts-p-v-1 guts-p-h-1-half"
        >
          <dgx-live-event-session
            [session]="session"
            (trackClick)="onLinkClick($event)"
          ></dgx-live-event-session>
        </div>
        <div *ngIf="canRegister" class="guts-m-t-1-half">
          <label
            for="saveSessionForLater"
            class="input-detail-modal__session-checkbox"
          >
            <input
              class="input-detail-modal__session-checkbox"
              id="saveSessionForLater"
              [checked]="resource.isQueued"
              type="checkbox"
              name="saveSessionForLater"
              (click)="queue()"
              data-dgat="input-card-4ef"
            />
            <span class="par par--small guts-m-l-half"
              ><b>{{ saveSessionCheckboxLabel }}</b>
              <df-icon
                [ngbTooltip]="saveSessionCheckboxTooltip"
                placement="top"
                attr.aria-label="{{ saveSessionCheckboxTooltip }}"
                class="color-shuttle guts-p-h-quart"
                icon="info"
                size="small"
              ></df-icon
            ></span>
          </label>
        </div>
      </div>

      <!-- Social Counts -->
      <div class="input-detail-modal__social-counts">
        <ng-container
          *ngTemplateOutlet="socialcounts; context: { useBadgeLayout: true }"
        ></ng-container>
      </div>

      <!-- Footer -->
      <footer class="input-detail-modal__footer guts-m-t-1-half">
        <div
          *ngIf="!isPreview && !!authUser"
          class="l_flexbar guts-p-h-2 action-buttons guts-p-v-1-half"
        >
          <!-- Primary Actions -->
          <div class="l_flex-grow l_flexbar">
            <ng-container *ngTemplateOutlet="primaryactions"></ng-container>
          </div>

          <!-- Secondary Actions-->
          <ng-container *ngTemplateOutlet="secondaryactions"></ng-container>
        </div>
      </footer>

      <!-- Comments -->
      <dgx-comment-thread
        *ngIf="showComments"
        class="input-detail-modal__comments"
        [resource]="resource"
        [isExpanded]="commentThreadExpanded"
        (commentAdded)="increaseCommentCount()"
        (commentDeleted)="decreaseCommentCount()"
      ></dgx-comment-thread>
    </div>
  </div>
</div>

<!-- Summary Template -->
<ng-template #summarytext>
  <div class="markdown input-detail-modal__summary" [innerHTML]="summary"></div>
</ng-template>

<!-- Metadata Template -->
<ng-template #metadata>
  <dgx-learning-resource-metadata
    *ngIf="!isPreview"
    [liveSessions]="resource.liveSessions"
    [isMoreDetailsView]="isDetailModal"
    [metaData]="resource.metaData"
    [resourceType]="resource.resourceType"
    [assignmentInfo]="resource.recommendationInfo"
    [assignmentCssClass]="'up-me badge-pill--compact'"
    [config]="{
      percentComplete: resource.percentComplete,
      isAcademy: resource.isAcademy,
      isv2Academy: resource.isv2Academy,
      compact: false,
    }"
    class="guts-m-b-half"
  ></dgx-learning-resource-metadata>
</ng-template>

<!-- Social Counts Template -->
<ng-template let-useBadgeLayout="useBadgeLayout" #socialcounts>
  <dgx-social-counts
    *ngIf="showSocialCounts"
    [item]="socialCountsModel"
    [config]="{
      disableSocialClick: disableSocialClick,
      hideSkills: useBadgeLayout,
    }"
    [useBadgeLayout]="useBadgeLayout"
    (toggleComments)="toggleCommentThread()"
  ></dgx-social-counts>
</ng-template>

<!-- Primary Actions Template-->
<ng-template #primaryactions>
  <!-- View Academy -->
  <dgx-view-academy-button
    *ngIf="resource.isAcademy"
    [url]="resource.url"
    size="xs"
    class="guts-m-r-half"
  ></dgx-view-academy-button>

  <!-- Completion -->
  <dgx-input-completion
    *ngIf="showCompletion"
    [class.guts-m-r-half]="!showMoreDetails"
    [isCompleted]="isCompleted"
    [recommendationInfo]="userRecHeaderItem"
    [input]="viewerInput || resource"
    size="xs"
  ></dgx-input-completion>

  <!-- View Details -->
  <dgx-action-button
    *ngIf="showMoreDetails"
    (click)="openInputPage()"
    [autoToggleState]="false"
    [class.guts-m-l-half]="!isCompleted"
    class="guts-m-r-half"
    data-dgprop-item-clicked="ViewDetails"
    dgatInput="input-detail-modal-1b9"
    size="xs"
  >
    {{ i18n.Core_ViewDetails }}
  </dgx-action-button>

  <!-- Open -->
  <dgx-action-button
    *ngIf="showOpenButton"
    (click)="goToUrl()"
    [autoToggleState]="false"
    class="guts-m-r-half"
    data-dgprop-item-clicked="Open"
    dgatInput="input-detail-modal-2c4"
    size="xs"
    [a11yContext]="getOpenText()"
  >
    {{ i18n.Core_Open }}
  </dgx-action-button>
</ng-template>

<!-- Secondary Actions -->
<ng-template #secondaryactions>
  <!-- Like -->
  <dgx-action-button
    *ngIf="isCompleted"
    class="guts-m-r-half"
    dgatInput="input-card-988"
    (click)="toggleLike()"
    [isActive]="resource.requestingUserRating === 1"
    [tooltip]="
      resource.requestingUserRating === 1 ? i18n.Core_Unlike : i18n.Core_Like
    "
    [autoToggleState]="true"
    size="xs"
    icon="thumbs-up"
    design="square"
    [isIconOnly]="true"
    [a11yContext]="getAllyTextForLike()"
  ></dgx-action-button>

  <!-- Share -->
  <dgx-action-button
    *ngIf="showShare"
    class="guts-m-r-half"
    dgatInput="input-detail-modal-505"
    (click)="recommend($event)"
    [tooltip]="i18n.Core_Recommend"
    [autoToggleState]="false"
    size="xs"
    icon="arrow-forward"
    design="square"
    [isIconOnly]="true"
    [a11yContext]="getAllyText('Core_ShareItem')"
  ></dgx-action-button>

  <!-- Save -->
  <dgx-action-button
    *ngIf="canAddToQueue"
    class="guts-m-r-half"
    dgatInput="input-detail-modal-7fc"
    (click)="queue()"
    [tooltip]="saveIconTooltip"
    [isActive]="resource.isQueued"
    size="xs"
    icon="bookmark"
    design="square"
    [isIconOnly]="true"
    [a11yContext]="getAllyText(saveIconHelpText)"
  ></dgx-action-button>

  <!-- Add Skills -->
  <dgx-action-button
    *ngIf="canAddSkills"
    class="guts-m-r-half"
    dgatInput="input-detail-modal-e97"
    (click)="tagResource($event)"
    [tooltip]="i18n.Core_AddTags"
    [autoToggleState]="false"
    size="xs"
    icon="tag"
    design="square"
    [isIconOnly]="true"
  ></dgx-action-button>

  <!-- Add to... -->
  <dgx-menu
    *ngIf="showAddButton"
    placement="bottom-right"
    [autoCloseOnItemSelect]="true"
    [menuConfig]="addToMenuConfig"
    [placementAdjustLeftRem]="0.5"
    [placementAdjustTopRem]="-0.5"
    [autoCloseOnItemSelect]="true"
  >
    <dgx-action-button
      dgatInput="input-card-6f3"
      class="guts-m-r-half"
      icon="plus"
      design="square"
      size="xs"
      [isIconOnly]="true"
      [autoToggleState]="false"
      [tooltip]="this.i18n.Core_AddTo"
      [a11yContext]="getAllyText('Core_AddItemTo')"
    ></dgx-action-button>
  </dgx-menu>

  <!-- Menu -->
  <ng-container *ngTemplateOutlet="menu"></ng-container>
</ng-template>

<!-- Menu Template -->
<ng-template #menu>
  <dgx-menu
    *ngIf="hasMenu"
    placement="bottom-right"
    [autoCloseOnItemSelect]="true"
    [menuConfig]="menuConfig"
    [placementAdjustLeftRem]="0.5"
    [placementAdjustTopRem]="-0.5"
    [autoCloseOnItemSelect]="true"
  >
    <dgx-action-button
      dgatInput="input-detail-modal-c95"
      icon="dots"
      design="square"
      size="xs"
      [isIconOnly]="true"
      [autoToggleState]="false"
      [tooltip]="i18n.Core_MoreOptions"
      [a11yContext]="getAllyText('Core_MoreOptionsForItem')"
    ></dgx-action-button>
  </dgx-menu>
</ng-template>

<!-- Loading skeleton Template -->
<ng-template #skeleton>
  <div class="guts-p-full-2">
    <dgx-skeleton type="card" layout="random"></dgx-skeleton>
  </div>
</ng-template>
