import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';

import { twMerge } from '../../utils/tw-merge';
import { LayoutAspect } from './layout.model';

/**
 * Layout component (v3 === Oct 2024 release)
 */
@Component({
  selector: 'da-layout-v3',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      [style]="{
        '--apollo-layout-background': configuration?.brand?.colors?.background,
        '--apollo-layout-text': configuration?.brand?.colors?.text,
        '--apollo-layout-highlight': configuration?.brand?.colors?.highlight,
      }"
      [class]="className"
    >
      <!-- Skip To Main Content Link -->
      <a href="#main-content" class="tw-sr-only">Skip to main content</a>

      <!-- Header -->
      <da-header *ngIf="showControls"
        [brand]="configuration?.brand"
        [features]="configuration?.features"
        [navigation]="configuration?.navigation"
        [className]="
          twMerge(
            'tw-transition-[margin] tw-reset tw-z-20',
            sidebarExpanded
              ? 'lg:tw-ml-[200px] rtl:lg:tw-ml-0 rtl:lg:tw-mr-[200px]'
              : 'lg:tw-ml-20 rtl:lg:tw-ml-0 rtl:lg:tw-mr-20'
          )
        "
        (search)="this.search.emit($event)"
        (addContent)="this.addContent.emit($event)"
      ></da-header>

      <!-- Sidebar -->
      <da-sidebar *ngIf="showControls"
        [brand]="configuration?.brand"
        [features]="configuration?.features"
        [navigation]="configuration?.navigation"
        [sidebarExpanded]="sidebarExpanded"
        [className]="
          twMerge(
            'tw-transition-[width] tw-hidden lg:tw-block tw-reset tw-z-20',
            sidebarExpanded ? 'lg:tw-w-[200px]' : 'lg:tw-w-20'
          )
        "
        (toggleExpanded)="sidebarExpanded = !sidebarExpanded"
        (switchRole)="this.switchRole.emit($event)"
      ></da-sidebar>

      <!-- Main Content -->
      <main
        id="main-content"
        [class]="
          twMerge(
            'tw-box-border tw-flex tw-min-h-dvh tw-flex-col tw-pt-16 tw-transition-[margin]',
            sidebarExpanded
              ? 'lg:tw-ml-[200px] rtl:lg:tw-ml-0 rtl:lg:tw-mr-[200px]'
              : 'lg:tw-ml-20 rtl:lg:tw-ml-0 rtl:lg:tw-mr-20'
          )
        "
      >
        <div class="tw-relative tw-grow">
          <ng-content></ng-content>
        </div>

        <!-- Footer -->
        <da-footer
          className="tw-reset"
          [footerBranding]="configuration?.features?.footerBranding"
        />
      </main>
    </div>
  `,
})
export class LayoutComponent extends SubscriberBaseDirective {
  @Input() className = '';
  @Input() configuration?: LayoutAspect; // `learner` or `admin` aspect of the LayoutConfiguration
  @Output() search = new EventEmitter<MouseEvent>();
  @Output() addContent = new EventEmitter<MouseEvent>();
  @Output() switchRole = new EventEmitter<MouseEvent>();

  /** Show or hide controls based on the mobile query param */
  public showControls = true;

  sidebarExpanded = false;
  twMerge = twMerge;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    // Subscribe to queryParams to handle mobile=1
    this.route.queryParams
    .pipe(this.takeUntilDestroyed())
    .subscribe((params) => {
      const mobile = params['mobile'];
      // Hide the layout if mobile=1
      this.showControls = mobile !== '1';
    });
  }
}
