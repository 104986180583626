import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanMatch,
  CanMatchFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrgBaseGuard implements CanActivate {
  protected authUser: AuthUser;
  protected orgId: number;
  protected routeDirectory: string = '';
  constructor(
    private authService: AuthService,
    @Inject(WindowToken) protected windowRef: Window,
    protected router: Router
  ) {
    this.authUser = this.authService.authUser;
    const url = this.windowRef.location.href;
    const match = url.match(/orgs\/(\d+)/); // match orgs/:id in the url
    const hasMatch = match && match[1];
    this.orgId = hasMatch ? Number(match[1]) : this.authUser.defaultOrgId;
  }

  protected get orgInfo(): OrgInfo | null {
    const results = this.authService.authUser?.orgInfo.find(
      (x) => x.organizationId === this.orgId
    );

    return results;
  }

  get hasPermission() {
    return !!this.orgInfo;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // If no permission, redirect to the orgs/<id> page which
    // checks all guards and finds 1st-permitted route

    return this.hasPermission || this.router.parseUrl(this.resolveOrgUrl(''));
  }

  protected resolveOrgUrl(routePath: string) {
    const basePath = `/orgs/${this.orgId}${this.routeDirectory}`;
    return `${basePath}${routePath}`;
  }
}
