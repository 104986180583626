import { Router } from '@angular/router';

import { Observable, forkJoin, of, pipe } from 'rxjs';
import {
  map,
  switchMap,
} from 'rxjs/operators';

import {
  BulkUploadGuard,
  PeopleRedirectGuard,
  SettingsSecurityGuard,
  SkillsRedirectGuard,
} from '@app/orgs/services/guards';
import {
  AuthService,
  ContextService,
  LDFlagsService,
  NavigationService,
  TargetsService,
  TrackerService,
  WebEnvironmentService,
} from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';

import { AnalyticsGuard } from '@app/analytics/guards/analytics.guard';
import { TagTypes } from '@app/analytics/models/constants';
import { OrgHelpMenuService } from '@app/orgs/services/org-help-menu.service';
import { OrganizationSupportInfo } from '@app/orgs/services/orgs.model';
import { TeamFlagsService } from '@app/team/services/team-flags.service';
import { LayoutConfiguration } from '@degreed/apollo-angular';

import { AuthUser } from '../../account/account-api.model';

import * as DegreedLayout from './data/degreed.config.json';
import * as WellsFargoLayout from './data/wells-fargo.config.json';
// import * as AllianzLayout from './data/allianz.config.json';
// import * as StarBucksLayout from './data/starbucks.config.json';

const customizations = {
  901724: WellsFargoLayout, // Production
  700059: WellsFargoLayout, // Beta 2: "Staging"
  700043: WellsFargoLayout, // Beta 1: "Beta"
  // 901746: StarBucksLayout,
  // 10095: AllianzLayout,
  // 10013: AllianzLayout,
};

/**
 * Private internal cache of the layout configuration
 */
let layout$: Observable<LayoutConfiguration>;

/**
 * Replace all `<orgId>` tokens with url-specific orgId or default orgId
 * This can be called at app initialization or at any time the user navigates to a new org
 * 
 * @param configuration T = LayoutConfiguration | LayoutAspect
 * @param user AuthUser
 */
export function updateOrgId<T = LayoutConfiguration>(
  configuration: T,
  user: AuthUser | undefined,
  forceUpdate = false
): T {
  // Determine which org the user has access
  const [isOrgView, orgId] = resolveOrgId(user);
  if (isOrgView || forceUpdate) {    
    const visitLinks = (node, key) => {
      switch (key) {
        case 'homeUrl':
        case 'href':
        case 'routerLink':
          const text: string = node[key];
          if (text.includes('<orgId>')) {
            node[key] = text.replace('<orgId>', orgId);
          }
          break;
      }
    };
  
    // Only do this the url starts with `orgs/:id`
    configuration = traverse(configuration, visitLinks);
  }

  return configuration;
}

/**
 * Update the "Admin" view (instead of the Learner view) items requiring admin access based on feature flags and user permissions
 */
export function updateAdminNavigation(
  configuration: LayoutConfiguration,
  auth: AuthService,
  featureFlag: LDFlagsService,
  guards: {
    analyticsGuard: AnalyticsGuard;
    peopleRedirectGuard: PeopleRedirectGuard;
    skillsRedirectGuard: SkillsRedirectGuard;
    bulkUploadGuard: BulkUploadGuard;
    settingsSecurityGuard: SettingsSecurityGuard;
  }
) {
  // Only update Admin configuration IF we have routed to Admin <pages className=""></pages>
  // Why? Because we need to know the specific orgId
  const isAdminRoute = window.location.href.includes('/orgs');
  if (!isAdminRoute) return configuration;

  const user = auth.authUser;
  const orgId = auth.authUser.defaultOrgId; // TODO: Get the orgId from the URL // Number(window.location.href.split('/')[4]);
  const orgInfo = auth.authUser.orgInfo.find(
    (x) => x.organizationId === orgId
  ) || { permissions: {} as any, settings: {} as any };
  const permissions = orgInfo.permissions;
  const settings = orgInfo.settings;
  const skillsClient =
    settings.skillInventoryClient || settings.skillAnalyticsClient;

  const showAcademies = featureFlag.showLearnInProductSwitcher;
  const showExtendedEnterprise = user?.hasChannel;
  const showDegreedSkills =
    user?.defaultOrgInfo?.settings?.enableSkillsPlatform &&
    user?.defaultOrgInfo?.permissions?.manageSkillsPlatform;
  const showRoles =
    settings.enableCareerPathing && settings.supportTargets && permissions.manageTargets;
  const showReports =
    ((settings.enableReportingInApp && auth.userCanViewReporting) ||
      permissions.manageReportingFTPScheduler) &&
    !skillsClient;
  const showInsights = permissions.viewReports;
  const showManagePlans =
    permissions.manageTargets && settings.enableCareerPathing;
  const showManageContent = permissions.manageContent;
  const showOrgGroups = permissions.manageGroups && !skillsClient;
  const showPathways = permissions.managePathways && !skillsClient;
  const showSkillSettings =
    (permissions.editSettings || permissions.editPermissions) && !skillsClient;
  const showAutomations = permissions.manageBusinessRules;
  const showManageMembers = guards.peopleRedirectGuard.hasPermission;
  const showAnalytics = guards.analyticsGuard.isAuthorized;
  const showSkillStandards = guards.skillsRedirectGuard.hasPermission;
  const showFileUpload = guards.bulkUploadGuard.canUpload();
  const showSessionManagement = guards.settingsSecurityGuard.hasPermission;
  const showContentMarketplace = false; // TODO: Determine value after Content Marketplace is integrated with the new layout

  const visitDGATs = (node, key, parent) => {
    const redact = () => deleteFromParent(node, parent);

    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.admin.sidebar.dashboard.learning':
          !showInsights && redact();
          break;

        case 'global.navigation.admin.sidebar.skills.dashboard':
          !showDegreedSkills && redact();
          break;
        case 'global.navigation.admin.sidebar.skills.scales':
          !showDegreedSkills && redact();
          break;
        case 'global.navigation.admin.sidebar.skills.inventory':
          !showDegreedSkills && redact();
          break;
        case 'global.navigation.admin.sidebar.skills.roles':
          !showRoles && redact();
          break;
        case 'global.navigation.admin.sidebar.skills.skill-standards':
          !showSkillStandards && redact();
          break;
        case 'global.navigation.admin.sidebar.skills.settings':
          !showSkillSettings && redact();
          break;

        case 'global.navigation.admin.sidebard.people.groups':
          !showOrgGroups && redact();
          break;
        case 'global.navigation.admin.sidebard.people.users':
          !showManageMembers && redact();
          break;

        case 'global.navigation.admin.sidebar.catalog.plans':
          !showManagePlans && redact();
          break;
        case 'global.navigation.admin.sidebar.catalog.pathways':
          !showPathways && redact();
          break;
        case 'global.navigation.admin.sidebar.catalog.content':
          !showManageContent && redact();
          break;
        case 'global.navigation.admin.sidebar.catalog.academies':
          !showAcademies && redact();
          break;

        case 'global.navigation.admin.sidebar.reporting.reports':
          !showReports && redact();
          break;
        case 'global.navigation.admin.sidebar.reporting.advanced-analytics':
          !showAnalytics && redact();
          break;

        case 'global.navigation.admin.sidebar.automations':
          !showAutomations && redact();
          break;
        case 'global.navigation.admin.sidebar.integrations.file-log':
          !showFileUpload && redact();
          break;
        case 'global.navigation.admin.sidebar.extended-enterprise':
          !showExtendedEnterprise && redact();
          break;

        case 'global.navigation.admin.sidebar.settings.security':
          !showSessionManagement && redact();
          break;
        case 'global.navigation.admin.sidebar.content-marketplace':
          !showContentMarketplace && redact();
          break;
      }
    }
  };

  return traverse(configuration, visitDGATs);
}

/**
 * Return a LayoutConfiguration object based on the user's default organization
 * NOTE: Currently we use compiled configuration files: embedded JSON that is runtime transformed.
 *       !! This configuration should be constructed on the server
 *
 * @returns Observable<LayoutConfiguration>
 */
export const buildLayoutConfiguration_v3 = (
  router: Router,
  auth: AuthService,
  translate: TranslateService,
  environment: WebEnvironmentService,
  targets: TargetsService,
  navigation: NavigationService,
  featureFlag: LDFlagsService,
  context: ContextService,
  teamFlags: TeamFlagsService,
  helpMenu: OrgHelpMenuService,
  trackerService: TrackerService,
  analyticsGuard: AnalyticsGuard,
  peopleRedirectGuard: PeopleRedirectGuard,
  skillsRedirectGuard: SkillsRedirectGuard,  
  bulkUploadGuard: BulkUploadGuard,
  settingsSecurityGuard: SettingsSecurityGuard
): Observable<LayoutConfiguration> => {

  // Customize the configuration JSON based on the user's organization, i18n and other information
  const buildLayout = pipe(
    switchMap((user: AuthUser | undefined) => {
      // Gather async data before customizing layout
      const organizationId = user?.defaultOrgId;
      const supportInfo$ = helpMenu.getSupportInfo(organizationId);
      const learnInSSOUrl$ = navigation.getLearnInSSOUrl(organizationId);
      const featuredPlanId$ = targets.getBrowseTarget(organizationId).pipe(map((target) => target.targetId));

      return forkJoin([ of(user), supportInfo$, featuredPlanId$, learnInSSOUrl$ ]);
    }),
    map(
      ([ user, supportInfo, featuredPlanId, learnInSSOUrl ]): LayoutConfiguration => {

        const analyticsUrl = analyticsGuard.getParentUrl( TagTypes.PRODUCT_SWITCHER );
        const organizationId = user?.defaultOrgId;
        const guards = { analyticsGuard, peopleRedirectGuard, skillsRedirectGuard, bulkUploadGuard, settingsSecurityGuard } as const;

        // Note: Update i18n first to get any placeholder variables used in translations that need to be replaced afterward
        let config = (customizations[user?.defaultOrgId] ?? DegreedLayout) as LayoutConfiguration;

            config = updatei18n(config, translate);
            config = updateUser(config, user, environment);
            config = updateUrls( config, user, featuredPlanId, learnInSSOUrl, analyticsUrl );

            config = updateLearnerNavigation( config, user, featureFlag, teamFlags, auth, context );
            config = updateAdminNavigation(config, auth, featureFlag, guards);
            
            config = updateHelpMenu(config, organizationId, supportInfo);
            config = addAnalyticTrackers(config, trackerService);
            config = validateConfiguration(config);

        return config;
      }
    )
  );

  if (!layout$) {
    layout$ = auth.authUser$.pipe(buildLayout);
  }

  return layout$;
};

/**
 * Replace all `<user>` tokens with current user's vanityUrl
 * Replace profile nav item image with current user's picture
 * @param configuration LayoutConfiguration
 * @param user AuthUser
 */
function updateUser(
  configuration: LayoutConfiguration,
  user: AuthUser | undefined,
  environment: WebEnvironmentService
): LayoutConfiguration {
  const userName = user?.viewerProfile.vanityUrl || '';
  let profileImage = user?.viewerProfile.picture;

  const visitLinks = (node, key) => {
    switch (key) {
      case 'homeUrl':
      case 'href':
      case 'routerLink':
        const text: string = node[key];
        if (text.includes('<user>'))
          node[key] = text.replace('<user>', userName);
        break;
    }
  };
  const visitDGATs = (node, key) => {
    switch (node.dgat) {
      case 'global.navigation.learner.sidebar.profile': // Update Profile image
        if (profileImage) {
          if (profileImage.startsWith('~')) {
            profileImage = environment.getBlobUrl(profileImage);
          }
          node.image = profileImage;
        }
        break;
    }
  };

  configuration = traverse(configuration, visitLinks);
  configuration = traverse(configuration, visitDGATs);

  return configuration;
}



/**
 * Update the navigation items based on feature flags and user permissions
 */
function updateLearnerNavigation(
  configuration: LayoutConfiguration,
  user: AuthUser | undefined,
  featureFlag: LDFlagsService,
  teamFlags: TeamFlagsService,
  auth: AuthService,
  context: ContextService
) {
  const userName = user?.viewerProfile.vanityUrl || '';
  const useLearnerHub = featureFlag.useLearnerHub;
  const showFlexEd =
    (user?.isPexUser && user?.isPexOrg) || user.defaultOrgInfo?.hasPex;
  const showNotifications = !!user;
  const showProfile = !!user;
  const showAssistant = featureFlag.lxpDegreedAssistant;
  const showOpportunities = user?.hasCareerMobility;
  const showSkillCoach = 
    user?.isSkillInventoryClient || user?.isSkillAnalyticsClient
      ? user?.isManager
      : user?.isManager &&
        user?.defaultOrgInfo.settings.enableTeamSpace &&
        (user?.defaultOrgInfo.settings.skillCoachFullOrgAccess ||
          teamFlags.teamSpaceEnabled);
  const showSkillCoachAssignments = featureFlag.skillCoach.managerAssignments;
  const showManageOrg =
    (auth.userCanManageLearnerOrg ||
      auth.userCanViewReporting ||
      auth.userCanManageSkillInventory ||
      auth.userCanManageSkillAnalytics) &&
    !context.isChannel();

  const visitDGATs = (node, key, parent) => {
    const updateLink = (link) => (node.routerLink = link);

    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.learner.features.show-admin-view':
          parent.visible = showManageOrg;
          break;

        case 'global.navigation.learner.sidebar.profile.flex-ed':
          !showFlexEd && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.opportunities':
          !showOpportunities && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.skill-coach':
          !showSkillCoach && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.skill-coach.assignments':
          !showSkillCoachAssignments && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.assistant':
          !showAssistant && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.notifications':
          !showNotifications && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.profile':
          !showProfile && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.home.my-learning':
          useLearnerHub && updateLink(`/${userName}/learnerhub/home`);
          break;
        case 'global.navigation.learner.sidebar.home.assignments':
          useLearnerHub && updateLink(`/${userName}/learnerhub/assignments`);
          break;
      }
    }
  };

  return traverse(configuration, visitDGATs);
}


/**
 * Update the navigation items with the correct URLs
 */
function updateUrls(
  configuration: LayoutConfiguration,
  user: AuthUser,
  featuredPlanId: number,
  learnInSSOUrl: string,
  analyticsUrl: string
) {
  const showFeatured =
    featuredPlanId &&
    !user?.isSkillAnalyticsClient &&
    !user?.isSkillInventoryClient;

  const visitor = (node, key, parent) => {
    const text = () => node[key];
    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.learner.sidebar.featured':
          !showFeatured && deleteFromParent(node, parent);
          break;
      }
    } else {
      switch (key) {
        // This 'text' replacement relies on i18n being updated beforehand
        case 'text':
          node[key] = text().replace(
            '{{orgName}}',
            user?.defaultOrgInfo?.name || 'LXP'
          );
          break;
        case 'routerLink':
        case 'href':
          node[key] = text()
            .replace('<analyticsUrl>', analyticsUrl)
            .replace('<learnInSSOUrl>', learnInSSOUrl)
            .replace( '<featuredPlanId>', featuredPlanId.toString());
          break;
      }
    }
  };

  return traverse(configuration, visitor);
}

/**
 * config = updateHelpMenu(config, organizationId, helpMenu)
 * Update the help menu items based on the organization's support info
 */
const updateHelpMenu = (
  configuration: LayoutConfiguration,
  organizationId: number,
  supportInfo: OrganizationSupportInfo
) => {
  if (!organizationId) supportInfo = { phone: '800.311.7061' };

  const visitDGATs = (node, key, parent) => {
    const updateByKey = (field, value) => {
      node[field] = value;
      !value && deleteFromParent(node, parent);
    };
    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.admin.sidebar.help.knowledge-center':
        case 'global.navigation.learner.sidebar.help.knowledge-center':
          updateByKey('href', supportInfo?.helpLink);
          break;
        case 'global.navigation.learner.sidebar.help.faq':
          updateByKey('href', supportInfo?.faq);
          break;
        case 'global.navigation.learner.sidebar.help.cookie-notice':
          !supportInfo?.showCookieLink && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.help.custom-link':
          node.text = supportInfo?.customText || '';
          node.href = `tel:${supportInfo?.phone}` || '';
          !supportInfo?.customLink && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.help.support-phone':
          node.text = supportInfo?.phone || '';
          node.href = `tel:${supportInfo.phone}`;
          !supportInfo?.phone && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.help.support-email':
          const hasEmail =
            supportInfo?.email && !supportInfo?.email.includes('@degreed.com');
          node.text = hasEmail ? supportInfo.email : '';
          node.href = hasEmail ? `mailto:${supportInfo.email}` : '';
          !hasEmail && deleteFromParent(node, parent);
          break;
      }
    }
  };

  return traverse(configuration, visitDGATs);
};

/**
 * For any `i18n` field, replace the associated field with translated value
 * @returns LayoutConfiguration
 */
function updatei18n(
  configuration: LayoutConfiguration,
  translate: TranslateService
): LayoutConfiguration {
  const MAPPINGS = {
    i18n: 'text',
    headerTitleI18n: 'headerTitle',
    buttonI18n: 'buttonText',
    titleI18n: 'titleText',
  };
  const i18n = i18nWithLog(translate);
  const visitor = (node, key) => {
    // For each node, check if it has an translation field
    switch (key) {
      case 'i18n':
      case 'headerTitleI18n':
      case 'buttonI18n':
      case 'titleI18n':
        const field = MAPPINGS[key];
        node[field] = i18n(node[key], node[field]);
        break;
    }
  };

  return traverse(configuration, visitor);
}

/**
 * Add analytics tracking to report clicks on the navigation items
 * @returns
 */
function addAnalyticTrackers(
  configuration: LayoutConfiguration,
  trackerService: TrackerService
): LayoutConfiguration {
  // Build analytics data for tracking
  const makeTrackData = (key: string) => {
    return {
      category: '',
      label: '',
      action: 'Global Navigation Item Clicked',
      properties: { itemClicked: key },
    };
  };
  const visitor = (node, key, showLog = true) => {
    switch (key) {
      case 'analytics':
        const itemId = node.analytics;
        const defaultVal = node.text || '';

        // Inject callback tracking function
        node['trackEvent'] = () => {
          const msg = `analytics with key: ${itemId} for ${defaultVal} (${node.i18n || ''})`;

          if (showLog) console.log(`Report ${msg}`);
          if (!itemId)
            console.error(
              `Missing analytics key: ${itemId} for ${defaultVal} (${node.i18n || ''})`
            );

          trackerService.trackEventData(makeTrackData(itemId));
        };
        break;
    }
  };

  return traverse(configuration, visitor);
}

/**
 * Translation util that logs missing translations
 * Keys can be comma-separated to try multiple keys until a valid translation is found
 * Note: Left-most key is the most recent... right-most key is the fallback
 */
const i18nWithLog =
  (translate: TranslateService) => (key: string, fallback: string) => {
    const allKeys = key.replace(/\s+/g, '').split(','); // This will remove all whitespace

    // Find "most recent" translation
    let value = '';
    let selectedKey = '';

    for (var i = 0; i < allKeys.length; i++) {
      if (!value && allKeys[i]) {
        selectedKey = allKeys[i];
        value = translate.instant(selectedKey) || '';
        
        // If ngx/translate returns the key, it means the translation is missing
        if (value === selectedKey) value = '';
      }
    }

    if ( !value ) {
      console.error(`i18n: ${selectedKey} using fallback: ${fallback}`);
      value = fallback;
    }

    return value || fallback;
  };

const validateConfiguration = (config: LayoutConfiguration) => {
  const filterNulls = (node: LayoutNode, key: string, parent: LayoutNode) => {
    switch (key) {
      case 'subItems':
      case 'children':        
      case 'top':
      case 'bottom':
        node[key] = node[key].filter((item) => item !== null);
        break;
    }
  };

  return traverse(clearRedactionQueue(config), filterNulls);
};

// *************************************************
// Tree Utils
// *************************************************

type LayoutNode = Record<string, any>;
type Visitor = (node: any, key: string, parent?: any) => void;

// Depth-first node tree scanning
const traverse = (node: any, visit: Visitor, parent?: LayoutNode) => {
  for (let key in node) {
    if (typeof node[key] === 'object') {
      visit(node, key, parent);
      traverse(node[key], visit, node);
    } else {
      visit(node, key, parent);
    }
  }
  return node;
};

/**
 * Remove node reference from parent...
 *
 * NOTE: this destructive process is allowed due to the nature
 * of the visitor pattern and tree traversal.
 */

let queue: (() => void)[] = [];

/**
 * Defer the removal of a node from its parent until the end of the traversal
 */
const deleteFromParent = (node: LayoutNode, parent: LayoutNode) => {
  const removeNode = () => {
    const key = Object.keys(parent).find((key) => parent[key] === node);
    if (key) {
      if (Array.isArray(parent)) {
        parent.splice(+key, 1);
      } else delete parent[key];
    }
  };
  queue.push(removeNode);
};

/**
 * Execute all deferred removals
 */
const clearRedactionQueue = (config: LayoutConfiguration) => {
  queue.forEach((fn) => fn());
  queue = [];

  return config;
};


/**
 * For the specified window.href and authenticated user, determine the orgId allowed for the user
 * @returns [boolean, string] - [isOrgView, orgId]
 */
const resolveOrgId = (user: AuthUser | undefined): [boolean, string] => {
  // match orgs/:d in the url to get the org id
  const url = window.location.href
  const match = url ? url.match(/orgs\/(\d+)/) : null; 
  const urlId = match ? Number(match[1]) : user?.defaultOrgId || 1;

  const results = user?.orgInfo.find((x) => x.organizationId === urlId);
  const orgId = results?.organizationId || user?.defaultOrgId || 1

  return [!!match, orgId.toString()];
}
