import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

export type ConfirmProps = {
  disableClose: boolean; // do not allow auto-close on backdrop clicks
  title: string;
  content: string;
  primaryBtnLabel: string;
  secondaryBtnLabel?: string;
  learnMoreLabel?: string;
  learnMoreUrl?: string;
  acknowledgeLabel?: string;
  onClose?: (confirmed: boolean) => void;
  mode?: ConfirmationMode;
};

export type ConfirmationMode = 'default' | 'destructive';

@Component({
  selector: 'da-confirm',
  template: `
    <div
      class="tw-max-w-72 tw-rounded-2xl tw-bg-white tw-p-4 tw-shadow-lg md:tw-max-w-md md:tw-p-6 lg:tw-max-w-xl"
      role="dialog"
      aria-labelledby="title"
      aria-describedby="description"
    >
      <div
        class="tw-mb-8 tw-flex tw-flex-col tw-items-start tw-gap-6 md:tw-flex-row"
      >
        <div class="tw-flex tw-flex-col">
          <ng-container [ngSwitch]="mode">
            <ng-container *ngSwitchCase="'default'">
              <h4 class="tw-mb-2 tw-font-extrabold tw-text-neutral-900" id="title">
                {{ title }}
              </h4>
              <p id="description" class="tw-text-neutral-700">
                {{ content }}
                <a
                  *ngIf="learnMoreLabel"
                  href="{{ learnMoreUrl }}"
                  target="_blank"
                  class="tw-font-semibold tw-text-blue-800"
                >
                  {{ learnMoreLabel }}
                </a>
              </p>
              <label
                *ngIf="checkboxControl"
                class="tw-mt-4 tw-inline-flex tw-items-center tw-gap-2 tw-text-neutral-700"
              >
                <input
                  class="tw-cursor-pointer"
                  type="checkbox"
                  [formControl]="checkboxControl"
                />
                {{ acknowledgeLabel }}
              </label>
            </ng-container>

            <ng-container *ngSwitchCase="'destructive'">
              <div class="tw-flex tw-gap-6">
                <div class="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-gap-2.5 tw-rounded-3xl tw-bg-red-100">
                  <da-icon icon="exclamation-triangle" class="tw-size-6 tw-text-red-800"></da-icon>
                </div>  
                <div>
                  <h4 class="tw-mb-2 tw-font-extrabold tw-text-neutral-900" id="title">{{ title }}</h4>
                  <p id="description" class="tw-text-neutral-700">
                    {{ content }}
                    <a *ngIf="learnMoreLabel" href="{{ learnMoreUrl }}" target="_blank" class="tw-font-semibold tw-text-blue-800"> {{ learnMoreLabel }} </a>
                  </p>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col tw-justify-end tw-gap-2 md:tw-flex-row md:tw-gap-4"
      >
        <button
          class="tw-btn-medium tw-btn-secondary-filled"
          (click)="handleClose(false)"
        >
          {{ secondaryBtnLabel }}
        </button>
        <button
          class="tw-btn-medium"
          [ngClass]="{'tw-btn-primary': mode === 'default', 'tw-btn-destructive': mode === 'destructive'}"
          [disabled]="(mode == 'destructive'? false: !checkboxControl.value)"
          (click)="handleClose(true)"
        >
          {{ primaryBtnLabel }}
        </button>
      </div>
    </div>
  `,
})
export class ConfirmComponent {
  @Input() public title: string = '';
  @Input() public content: string = '';
  @Input() public primaryBtnLabel: string = '';
  @Input() public secondaryBtnLabel?: string = '';
  @Input() public learnMoreLabel?: string = '';
  @Input() public learnMoreUrl?: string = '';
  @Input() public acknowledgeLabel?: string = '';
  @Input() public mode?: ConfirmationMode = 'default';
  @Output() public close: EventEmitter<boolean> = new EventEmitter<boolean>();

  checkboxControl = new FormControl(false);

  constructor(
    @Inject(DIALOG_DATA)
    public data: ConfirmProps
  ) {
    this.title = data.title;
    this.content = data.content;
    this.primaryBtnLabel = data.primaryBtnLabel;
    this.secondaryBtnLabel = data.secondaryBtnLabel;
    this.learnMoreLabel = data.learnMoreLabel;
    this.learnMoreUrl = data.learnMoreUrl;
    this.acknowledgeLabel = data.acknowledgeLabel;
    this.mode = data.mode ?? 'default';
  }

  handleClose(confirmed: boolean): void {
    if (!!this.data.onClose) {
      this.data.onClose(confirmed);
    }
    this.close.emit(confirmed);
  }
}
