import { TrackingEventArgs } from '../../shared/services/tracking.model';
import { ContextService } from '@app/shared/services/context.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { DeleteModalInputs } from '@app/shared/components/modal/delete-confirmation-modal/delete-modal.component';
import { GroupService } from '@app/shared/services/group.service';
import { switchMap } from 'rxjs/operators';
import { ModalService } from '@app/shared/services/modal.service';
import {
  SimpleModalInputBindings,
  SimpleModalComponent,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { GroupPrivacyLevel } from '@app/groups/group-api';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Injectable } from '@angular/core';
import { OrgGroup } from '@app/orgs/org-group-types';
export interface ManagedGroup extends OrgGroup {
  isSelected?: boolean;
  privacyDisplayText: string;
  url: string;
  showGroupLink: boolean;
  showLeaveButton: boolean;
  showJoinButton: boolean;
  showRequestButton: boolean;
  showPendingRequest: boolean;
  membershipType: 'Static' | 'Dynamic';
}
export const enum GroupType {
  Group = 0,
  ManagerGroup = 1,
}

@Injectable({
  providedIn: 'root',
})
export class OrgGroupsService {
  public isLoading$: Subject<boolean> = new Subject<boolean>();
  public isNotFound$: Subject<boolean> = new Subject<boolean>();
  public i18n = this.translate.instant([
    'OrgGroupsCtrl_LeaveGroupModalHeader',
    'OrgGroupsCtrl_LeaveGroupModalBodyText',
    'OrgGroupsCtrl_OpenGroup',
    'OrgGroupsCtrl_ClosedGroup',
    'OrgGroupsCtrl_PrivateGroup',
    'OrgGroupsCtrl_AdministrativeGroup',
  ]);
  constructor(
    private contextService: ContextService,
    private groupService: GroupService,
    private http: NgxHttpClient,
    private modalService: ModalService,
    private trackerService: TrackerService,
    private translate: TranslateService
  ) {}

  public groupsToManage(
    orgId: number,
    skip: number,
    take: number,
    searchTerm: string,
    canManageGroups: boolean
  ): Observable<{ hasMoreGroups: boolean; groups: ManagedGroup[] }> {
    return this.getGroupsToManage(orgId, skip, take, searchTerm).pipe(
      switchMap((response) => {
        const managedGroups = response.groups.map((group) => {
          const privacyDisplayText = this.getPrivacyDisplayText(
            group.privacyLevel
          );
          const url = this.getGroupUrl(group.groupId);
          const displayOptions = this.getGroupDisplayOptions(
            group,
            canManageGroups
          );
          return {
            ...group,
            url,
            privacyDisplayText,
            ...displayOptions,
          } as ManagedGroup;
        });
        return of({
          hasMoreGroups: response.hasMoreGroups,
          groups: managedGroups,
        });
      })
    );
  }

  public getPrivacyDisplayText(privacyLevel: GroupPrivacyLevel) {
    switch (privacyLevel) {
      case GroupPrivacyLevel.Open:
        return this.i18n.OrgGroupsCtrl_OpenGroup;
      case GroupPrivacyLevel.Closed:
        return this.i18n.OrgGroupsCtrl_ClosedGroup;
      case GroupPrivacyLevel.Private:
        return this.i18n.OrgGroupsCtrl_PrivateGroup;
      case GroupPrivacyLevel.Administrative:
        return this.i18n.OrgGroupsCtrl_AdministrativeGroup;
    }
  }

  public showLeaveGroupModal(group: OrgGroup) {
    const inputs: SimpleModalInputBindings = {
      headerText: this.i18n.OrgGroupsCtrl_LeaveGroupModalHeader,
      bodyText: this.i18n.OrgGroupsCtrl_LeaveGroupModalBodyText,
      canCancel: true,
    };
    return this.modalService
      .show(SimpleModalComponent, {
        inputs,
      })
      .pipe(
        switchMap(() => {
          return this.groupService.leave({
            groupId: group.groupId,
            name: group.name,
            privacyLevel: group.privacyLevel,
            userCount: group.userCount,
            interestNames: null,
          });
        })
      );
  }

  public showBulkDeleteModal(selectedGroupCount: number) {
    this.trackerService.trackEventData({
      action: 'Groups Bulk Delete Initiated',
    });
    return this.modalService.showDeleteConfirmation({
      title:
        selectedGroupCount > 1
          ? this.translate.instant('OrgGroupsCtrl_DeletePluralPrompt', {
              count: selectedGroupCount,
            })
          : this.translate.instant('OrgGroupsCtrl_DeletePrompt'),
      description: this.translate.instant(
        'OrgPathwayManagerCtrl_DeletePromptDescription'
      ),
      deleteInstructions: this.translate.instant(
        'OrgPathwayManagerCtrl_DeleteInstructions',
        {
          delete: 'DELETE',
        }
      ),
    } as DeleteModalInputs);
  }

  public canVisitGroupPage(canManageGroups: boolean, group: OrgGroup) {
    return canManageGroups || group.isMember;
  }

  public canLeaveGroup(
    isRestrictedGroup: boolean,
    privacyLevel: GroupPrivacyLevel,
    isMember: boolean
  ) {
    return (
      !isRestrictedGroup &&
      privacyLevel !== GroupPrivacyLevel.Administrative &&
      isMember
    );
  }

  public canJoinGroup(
    canManageGroups: boolean,
    isMember: boolean,
    isPendingMember: boolean,
    privacyLevel: GroupPrivacyLevel
  ) {
    return (
      !isMember &&
      !isPendingMember &&
      privacyLevel !== GroupPrivacyLevel.Administrative &&
      (privacyLevel === GroupPrivacyLevel.Open || canManageGroups)
    );
  }

  public canRequestToJoinGroup(canManageGroups: boolean, group: OrgGroup) {
    return (
      !group.isMember &&
      group.privacyLevel === GroupPrivacyLevel.Closed &&
      !canManageGroups &&
      !group.pendingJoinRequest
    );
  }

  public isRequestToJoinPending(canManageGroups: boolean, group: OrgGroup) {
    return (
      group.privacyLevel === GroupPrivacyLevel.Closed &&
      !canManageGroups &&
      group.pendingJoinRequest
    );
  }

  public trackGroupAction(payload: TrackingEventArgs) {
    // wrapper to avoid importing tracker into org-groups
    //  component when we already have it here
    this.trackerService.trackEventData(payload);
  }

  private getGroupUrl(groupId: number) {
    const isChannel = this.contextService.urlInChannel();
    const groupUrl = `/groups/${groupId}`;

    if (isChannel) {
      return this.contextService.addContextToUrl(groupUrl, 'channel');
    } else {
      return groupUrl;
    }
  }

  private getGroupDisplayOptions(group: OrgGroup, canManageGroups: boolean) {
    const { isRestricted, privacyLevel, isMember, pendingJoinRequest } = group;
    return {
      showGroupLink: this.canVisitGroupPage(canManageGroups, group),
      showLeaveButton: this.canLeaveGroup(isRestricted, privacyLevel, isMember),
      showJoinButton: this.canJoinGroup(
        canManageGroups,
        isMember,
        pendingJoinRequest,
        privacyLevel
      ),
      showRequestButton: this.canRequestToJoinGroup(canManageGroups, group),
      showPendingRequest: this.isRequestToJoinPending(canManageGroups, group),
    };
  }

  private getOutcomeGroups(orgId, skip, take, term) {
    return this.http.get<{ hasMoreGroups: boolean; groups: OrgGroup[] }>(
      '/organizations/outcomeGroups',
      {
        params: {
          id: orgId,
          skip,
          take,
          term,
        },
      }
    );
  }

  private getGroupsToManage(
    orgId,
    skip,
    take,
    searchTerm,
    groupType?: GroupType
  ) {
    return this.http.get<{ hasMoreGroups: boolean; groups: OrgGroup[] }>(
      '/organizations/groupsToManage',
      {
        params: {
          id: orgId,
          skip,
          take,
          term: searchTerm,
          groupType: groupType,
        },
      }
    );
  }
}
