import { of } from 'rxjs';
import {
  useApolloLayout,
  useApolloLayout_Admin_V3,
  useApolloLayout_Learner_V2,
  useApolloLayout_Learner_V3,
} from '@degreed/apollo-angular';

import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/shared/services/auth.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';

// Used for AppLayout v3
import {
  BulkUploadGuard,
  PeopleRedirectGuard,
  SettingsSecurityGuard,
  SkillsRedirectGuard,
} from '@app/orgs/services/guards';

import { AnalyticsGuard } from '../analytics/guards/analytics.guard';
import { OrgHelpMenuService } from '../orgs/services/org-help-menu.service';
import { ContextService } from '../shared/services/context.service';
import { LDFlagsService } from '../shared/services/ld-flags.service';
import { NavigationService } from '../shared/services/navigation.service';
import { TargetsService } from '../shared/services/targets.service';
import { TeamFlagsService } from '../team/services/team-flags.service';
import { TrackerService } from '../shared/services';

import { buildLayoutConfiguration_v3 as builder_v3 } from './v3';
import { buildLayoutConfiguration_v2 as builder_v2 } from './v2';
import { APOLLO_LAYOUT_TOKEN } from './layout.token';

/**
 * Provider for Apollo Layouts (v2 + v3)
 * Used for the Apollo Navigation configuraiton
 */
export const ApolloLayoutProvider = {
  provide: APOLLO_LAYOUT_TOKEN,
  useFactory: (...args) => {
    const NOOP = (...deps) => of({ admin: {}, learner: {} });

    // Which app layout configurator should we use: v2 or v3?

    const useV3 = useApolloLayout_Learner_V3() || useApolloLayout_Admin_V3();
    const useV2 = useApolloLayout_Learner_V2() || useApolloLayout();
    const buildLayout = useV3 ? builder_v3 : useV2 ? builder_v2 : NOOP;

    return buildLayout.apply(null, args);
  },
  deps: [
    Router,
    AuthService,
    TranslateService,
    WebEnvironmentService,
    TargetsService,
    NavigationService,
    LDFlagsService,
    ContextService,
    TeamFlagsService,
    OrgHelpMenuService,
    TrackerService,
    AnalyticsGuard,
    PeopleRedirectGuard,
    SkillsRedirectGuard,
    BulkUploadGuard,
    SettingsSecurityGuard,
  ],
};
