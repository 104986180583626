import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { twJoin, twMerge } from '../../utils';
import { toastAnimations } from './toast.animations';
import { ToastOptions } from './toast.model';
import { TOAST_DATA } from './toast.tokens';

/**
 * Toast component to display a static message to the user
 * and autoCloses after a certain time, unless including the close button.
 *
 * Note: Since all the inputs/outputs are handled by the ToastService TOAST_DATA
 * this component does NOT have formal @Input/@Output decorators
 *
 * Usage to show a toast message to the user with message, title, etc.
 * @code
 *   this.toastService.showToast('message', {
 *     type: 'success',
 *    ...<ToastOptions>,
 *   });
 *
 * Extras:
 *  TODO: AutoClose will stop on mouse enter and start on mouse leave
 *  i18n translations are performed OUTSIDE this component
 */
@Component({
  selector: 'da-toast',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class:
      'tw-flex tw-w-full tw-flex-col tw-items-center tw-space-y-4 sm:tw-items-end',
  },
  template: `
    <div
      class="tw-pointer-events-auto tw-min-w-72 tw-overflow-hidden tw-rounded-lg tw-bg-white tw-p-4 tw-shadow-xl tw-ring-1 tw-ring-neutral-200"
      [class]="
        twJoin(
          options.title || !options.action ? 'tw-w-72' : 'tw-w-full tw-max-w-96'
        )
      "
      @fadeInOut
    >
      <div class="tw-flex tw-items-start tw-gap-x-3">
        <da-icon
          [icon]="icon"
          className="tw-text-neutral-600"
          [className]="
            twMerge(
              options.type === 'default' ? 'tw-text-neutral-600' : '',
              options.type === 'info' ? 'tw-text-purple-600' : '',
              options.type === 'success' ? 'tw-text-green-600' : '',
              options.type === 'warning' ? 'tw-text-yellow-600' : '',
              options.type === 'error' ? 'tw-text-red-600' : '',
              'tw-h-6 tw-w-6'
            )
          "
        />

        <div
          class="tw-flex tw-min-h-6 tw-flex-1"
          [class]="
            twJoin(
              options.title
                ? 'tw-flex-col tw-justify-center tw-gap-1'
                : 'tw-items-center tw-overflow-hidden'
            )
          "
        >
          @if (options.title) {
            <span
              class="tw-truncate tw-text-sm tw-font-semibold"
              [title]="options.title"
              >{{ options.title }}</span
            >
          }

          <p
            class="tw-grow tw-text-xs"
            [class]="
              twJoin(
                options.title || !options.action
                  ? 'tw-line-clamp-2'
                  : 'tw-truncate'
              )
            "
            [title]="options.message"
          >
            {{ options.message }}
          </p>

          @if (options.action) {
            <div>
              <button
                type="button"
                class="tw-btn-tertiary"
                (click)="options.action()"
              >
                {{ options.actionLabel }}
              </button>
            </div>
          }
        </div>

        @if (options.closeLabel) {
          <button
            type="button"
            class="tw-btn-icon tw-btn-secondary-filled"
            (click)="options.close()"
          >
            <da-icon icon="x-mark" className="tw-h-4 tw-w-4" />
            <span class="tw-sr-only">{{ options.closeLabel }}</span>
          </button>
        }
      </div>
    </div>
  `,
  animations: [toastAnimations],
})
export class ToastComponent {
  twJoin = twJoin;
  twMerge = twMerge;
  icon!: string;

  constructor(@Inject(TOAST_DATA) public options: ToastOptions) {
    this.setIcon();
  }

  /** If the action button should be shown. */
  get hasAction(): boolean {
    return !!this.options.actionLabel;
  }

  private setIcon() {
    switch (this.options.type) {
      case 'success':
        this.icon = 'check-circle';
        break;
      case 'warning':
        this.icon = 'exclamation-triangle';
        break;
      case 'error':
        this.icon = 'exclamation-circle';
        break;
      case 'info':
      case 'default':
      default:
        this.icon = 'information-circle';
        break;
    }
  }
}
